.brandDropDownMain {
    border: none !important;
    
}

.brand-dropdown-menu {
    min-width: 100% !important;
    overflow-y: auto !important;
    max-height: 500px !important;

    scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #aaa #eee; /* For Firefox */

  /* For Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa; /* Color of the thumb (the draggable part) */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #666; /* Color of the thumb on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: #eee; /* Color of the track (the background of the scrollbar) */
  }
}

.brandDropdown-item {
    min-width: 100% !important;
}

.brandDropdown .dropdown-toggle.custom-btn {
    min-width: 100%;
	background-color: white !important;
    color:  #9c9ea2 !important;
    // border: none !important;
    text-align: left;  

    width: 100%;
    display: flex;
    align-items: center;
	// padding-inline: 14px;
	// padding-block: 8px;
    padding: 0px !important;
	border-radius: 8px;
	color: #9c9ea2;
	border: 1px solid #D0D5DD !important;
	outline: none;
	list-style: none;
}

.tracking.dropdown.status.toggle {
  display: flex;
  align-items: center;
  gap: 10px !important;
  background-color: white !important;
  border: 1px solid rgba(211, 211, 211, 0.37) !important;
  border-radius: 20px !important;
  padding-block: 3px !important;
  color: black !important;
  margin-bottom: 20px !important;
}

.tracking.status.toggle {
  // display: flex;
  // align-items: center;
  // gap: 10px !important;
  background-color: white !important;
  // border: 1px solid rgba(211, 211, 211, 0.37) !important;
  // border-radius: 20px !important;
  // padding-block: 5px !important;
  color: black !important;
  // margin-bottom: 20px !important;

}

.tracking.dropdown.status.toggle::after {
  display: none !important;
  background-image: none !important;
}


.tracking.dropdown.status.toggle.green {
  color: #07AD36 !important;
  background-color: #EBFFF0 !important;
  border: 1px solid #07AD36 !important;
}

.tracking.dropdown.status.toggle.red {
  color: #D71513 !important;
  background-color: #FFF0F0 !important;
  border: 1px solid #D71513 !important;
}

.tracking.dropdown.status.toggle.yellow {
  color: #D8AB0D !important;
  background-color: #FFFCF9 !important;
  border: 1px solid #D8AB0D !important;
}

.tracking.dropdown.status.toggle.orange {
  color: #EE8204 !important;
  background-color: #FFFCF3 !important;
  border: 1px solid #EE8204 !important;
}

.tracking.dropdown.status.toggle.purple {
  color: #0B07AD !important;
  background-color: #F1F1FF !important;
  border: 1px solid #0B07AD !important;
}


.tracking.dropdown.status.toggle.blue {
  color: #1A56AE !important;
  background-color: #ECF6FF !important;
  border: 1px solid #1A56AE !important;
}

.tracking.status.toggle.green {
  color: #07AD36 !important;
  background-color: #EBFFF0 !important;
  
}


.tracking.status.toggle.yellow {
  color: #D8AB0D !important;
  background-color: #FFFCF9 !important;
  
}



.tracking.status.toggle.orange {
  color: #EE8204 !important;
  background-color: #FFFCF3 !important;
  
}

.tracking.status.toggle.purple {
  color: #0B07AD !important;
  background-color: #F1F1FF !important;
  
}


.tracking.status.toggle.blue {
  color: #1A56AE !important;
  background-color: #ECF6FF !important;
  
}

.tracking.status.toggle.red {
  color: red !important;
  background-color: #FFF0F0 !important;
  
}

.tracking.status.toggle.red:hover {
  color: red !important;
  background-color: #faa4a4 !important;
  
}

.tracking.status.toggle.green:hover {
  color: #09a736 !important;
  background-color: #c0ffd0 !important;
  
}


.tracking.status.toggle.yellow:hover {
  color: #ba9308 !important;
  background-color: #fce7d3 !important;
  
}

.tracking.status.toggle.orange:hover {
  color: #be6704 !important;
  background-color: #f9e5a9 !important;
  
}

.tracking.status.toggle.purple:hover {
  color: #08058c !important;
  background-color: #b0b0f9 !important;
  
}


.tracking.status.toggle.blue:hover {
  color: #1A56AE !important;
  background-color: #ECF6FF !important;
  
}

.tracking.dropdown.status.menu {
  min-width: auto !important;
  padding: 0px !important;
}