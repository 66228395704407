// delete review modla

.deleteReviewBtn {
	padding-inline: 20px;
}

.reviewbtnsMainRow {
	// display: flex;
	// width: 100%;
	// justify-content: flex-end;
	float: right;
}

.cancleBtnReview {
	color: black;
	margin-right: 10px;
}

.titleReviewDelete {
	font-size: 24px;
    padding-left: 25px;
    font-weight: 600;
    background: white;
    width: 100%;
    color: black;
}

.modal-dialog-centeredDeleteReview {
	top: 25%;
}

.reviewsTablePText {
	font-size: 14px;
}

