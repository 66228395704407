.modal-dialog-centeredupdateVariant {
    min-width: 1000px !important;
    background: transparent;
    height: fit-content;

}

.updateVariantModalHeader {
    background: white;

}

.titleupdateVariant {
    font-size: 24px;
    padding-left: 25px;
    padding-bottom: 25px;
    font-weight: 600;
    background: white;
    width: 100%;
    color: black;
}

.updateVariantFieldsMainContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.updateVariantFlexInputsDiv {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
}

.updateVariantInputDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
}

.updateVariantSingleInputField {
    width: auto;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    padding-inline: 14px;
    padding-block: 10px;
    outline: none;
}

.updateVariantInputLabel {
    font-size: 14px;
    font-weight: 600;
    color: black;
}