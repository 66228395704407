.variationInputFieldSingleVariationForm {   
    min-width: 50%;
	background-color: white !important;
    color:  #9c9ea2 !important;
    // border: none !important;
    text-align: left;  
    width: 100%;
	padding-inline: 14px;
	padding-block: 8px;
	border-radius: 8px;
	color: #9c9ea2;
	border: 1px solid #D0D5DD !important;
	outline: none;
	list-style: none;
}