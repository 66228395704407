
input {
    border: 1px solid #D0D5DD;
    background: white;
    border-radius: 8px;
    padding-inline: 14px;
    padding-block: 8px;
    outline: none;
    width: 100%;
}
.related-product-page-card{
    min-height: 250px;
}
.editProductInputsMainContainer  {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    div[class*='-container']{
        width: 100%;
    }
    .editProductInputDiv-full-width{
        flex: 0 0 92%;
    }
}

.editProductInputsMainContainer > div {
    flex: 0 0 calc(45%);
}

.editProductInputDiv {   
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
}

.editProductInputLabel {
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.formBtnsEditProductDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    margin-bottom: 50px;
}

.editProductFormBtn {
    border: 1px solid #D71513;
    padding-inline: 78px;
    padding-block: 12px;
    border-radius: 8px;
    font-size: 16px;
    background: transparent;
    outline: none;
    color: #D71513;
}

.editProductFormBtn.save {
    background: #D71513;
    border: none;
    color: white;
}

