.categoryDropDownMain {
    border: none !important;
    
}

.category-dropdown-menu {
    min-width: 100% !important;
    overflow-y: auto !important;
    max-height: 500px !important;

    scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #aaa #eee; /* For Firefox */

  /* For Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa; /* Color of the thumb (the draggable part) */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #666; /* Color of the thumb on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: #eee; /* Color of the track (the background of the scrollbar) */
  }

}

.category-dropdown-menu.flashsaleProductListing {
  max-width: 50% !important;
}

.categoryDropdown-item {
    min-width: 100% !important;
}

.categoryDropdown .dropdown-toggle.custom-btn {
    min-width: 100%;
	background-color: white !important;
    color:  #9c9ea2 !important;
    // border: none !important;
    text-align: left;  
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px !important;
	border-radius: 8px;
	color: #9c9ea2;
	border: 1px solid #D0D5DD !important;
	outline: none;
	list-style: none;
}

