
// texteditor
.react-quill-textEditor {
   
    position: relative;
    // min-height: 250px; /* Set a minimum height */
    // max-height: 700px; /* Set a maximum height if desired */
	// height: 320px;
    margin-bottom: 20px;
    // padding-bottom: 50px;
	border-radius: 18px;
    .ql-toolbar {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 14px !important;
        font-family: Poppins !important;
    };
    .ql-container {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        font-size: 16px;
        color: black;
        font-family: Poppins, sans-serif;
        min-height: 180px;
    }
}


.description-error {
    color: #D71513;
    font-size: 14px;
    font-weight: 500;
}