/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
img{
	max-width: 100%;
}
.parent{ margin: auto;}
.myHeight{ height: 100vh; display: flex; align-items: center; text-align: center;}
.myCapitalized { text-transform: capitalize}

.myHeightExistingVariationSpinner {
	height: 200px;
	display: grid;
	place-items: center;
}

// navbar header
.navbarHeaderMainDivFlex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
// VerticalMenuHeader

.logoVerticalMenuHeader {
	width: 40px;
	height: auto;
}

.dropDown-style-v2{
	.btn-secondary:hover:not(.disabled):not(:disabled){
		box-shadow: none;
	}
	.dropdown-item{
		&:hover{
			color: #ffffff;
			background: var(--bs-dropdown-link-hover-color);
		}
	}
}



// addModule

.form-labeladdModule {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px dashed #d4d4d4a0;
	width: 190px;
	height: 190px;
	cursor: pointer;
	border-radius: 5px;
}

.form-labeladdModule.redBorder {
	border: 2px dashed #d67775;

}

.logoIconaddModule {
	width: 78px;
	height: auto;
}

.flexDivLogoaddModule {
	display: flex;
	flex-direction: column;
	align-items: center;
	
}

.modal-dialog-centeredaddModule {
	width: 800px;
	padding: 45px;
}

.row-to-edit {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	gap: 32px;
}

.selection-namingaddModule {
	color: #D71513;
	font-size: 14px;
	font-weight: 500;
}

.modalHeaderaddModule {
	font-weight: 800;
}

.selected-image-containeraddModule {
	position: relative;
	width: 190px;
	height: 190px;
	display: flex;
	justify-content: center;
	align-items: center;
	// border: 1px solid #E5E5E5;
	border-radius: 8px;
}
.image-container{
	position: relative;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 8px;
	}
	&::before{
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		background: rgba(0,0,0,.3);
		top: 0;
		left: 0;
		border-radius: 12px;
		z-index: 90;
		transition: all 0.3s ease-in;
		opacity: 0;
	}
	.image-remove-btn{
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		bottom: 0;
		margin: 0 auto;
		z-index: 99;
		height: 36px;
		width: 120px;
		transform: translateY(-10px);
		transition: all 0.3s ease-in;
		opacity: 0;
	}
	&:hover{
		&::before{
			opacity: 1;
		}
		.image-remove-btn{
			opacity: 1;
		}
	}
	&.related-product-image-container{
		&:hover{
			&::before{
				opacity: 0;
			}
		}
	}
}
.related-orduct-brand-block{
	margin-bottom: 15px;
}
.selected-image-containeraddModuleAddBanner {
	position: relative;
	width: 100%;
	height: 230px;
	display: flex;
	justify-content: center;
	align-items: center;
	// border: 1px solid #E5E5E5;
	border-radius: 8px;
}

.selected-image-containeraddModuleAddBanner.menuPage {
	height: 365px !important;
}

.selected-imageaddModule {
	position: relative;
	width: 100%;
	height: auto;
	border-radius: 13px;
}

.remove-iconaddModule {
	position: absolute;
	top: 0px;
	right: 5px;
	color: white;
	// background:#2c333e;
	// border-radius: 50%;
	padding: 4px;
}

.remove-iconaddModuleAddBanner {
	position: absolute;
	top: 0px;
	right: 5px;
	color: white;
	// background:#2c333e;
	// border-radius: 50%;
	padding: 4px;
}


.selected-imageaddModuleAddBanner {
	position: relative;
	max-width: 100%;
	min-height: auto;
	max-height: 100%;
	border-radius: 13px;
}

.productDetails__content__pricing{

	&--off--pricing{
		color: var(--Grey---767676, #888686);
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-decoration: line-through;
	}
	&--on--pricing{
		color: var(--Base-Black, #1C1C1C);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	&--discount--pricing{
		color: #EB5806;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
}
.productDetails__content__pricing__block{
	display: flex;
	gap: 15px;
}
.orderedProductVariationBlock-color{
	position: relative;
}
.orderedProductVariationBlock-color-title{
	color: var(--Base-Black, #1C1C1C);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-right: 10px;
}
.orderedProductVariationSKUBox-title{
	color: var(--Base-Black, #1C1C1C);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-right: 10px;
}
.orderedProductVariationQuantityBlock{
	display: flex;
    gap: 40px;
}
.orderedProductVariationBlock-color-title-value{
	margin-left: 27px;
}
.orderedProductVariationQuantityBox{
	display: flex;
	width: 69px;
	height: 24px;
	padding: 2px 10px;
	justify-content: center;
	align-items: center;
	gap: 11px;
	background: #F4F4F4;
}
.orderedProductVariationBlock{
	display: flex;
	gap: 15px;
}

// dataTable 

.dataTableInnerDiv {
	padding-inline: 24px;
	padding-block: 24px;
}

.eyeToggleBtndataTable {
	border: none;
	background: transparent;
	color: #D71513;
}

.eyeToggleIcondataTable {
	color: #D71513;
	width: 24px;
}

.headerRowDivFlexdataTable {
	display: flex;
	align-items: flex-end;
	width: 100%;
	justify-content: space-between;
	// padding-inline: 24px;
	&.mt-20{
		margin-top: 20px;
	}
}
.product-filter-status-selector{
	[class*='-control']{
		width: 190px;
	}
}
.headerRowDivFlexdataTable.category {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

.headerRowDivFlexdataTableReviews {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	// padding-inline: 24px;
}

.headerRowDivFlexdataTableCatType {
	display: flex;
	align-items: flex-end;
	width: 100%;
	justify-content: space-between;
}


.headerRowDivFlexdataTableFirstChild,
.headerRowDivFlexdataTableSecondChild {
	display: flex;
	align-items: flex-end;
}

.headerRowDivFlexdataTableSecondChild {
	gap: 18px;
}

.headerRowDivFlexdataTableCatTypeChild {
	display: flex;
	align-items: flex-end;
	gap: 25px;
}


.pageAddBrandDivFlexdataTable {
	display: flex;
	// gap: 12px;
	align-items: flex-start;
}

.pageAddBrandDivFlexdataTable.codesListingPage {
	justify-content: space-between;
}

.pageAddBrandDivFlexdataTableCategoryType {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 1400px) {
	.headerRowDivFlexdataTableCatType {
		display: flex;
		// flex-direction: column;
		// align-items: flex-end;
		width: 100%;
		justify-content: space-between;
	}

	// .headerRowDivFlexdataTableCatTypeChild {
		// align-self: flex-end !important;
		// width: 100%;
		// margin-bottom: 30px;
	// }
	
	// .pageAddBrandDivFlexdataTableCategoryType {
	// 	align-self: flex-start !important;
	// 	width: 100% !important;
	// 	margin-bottom: 30px !important;
	// }
}

// updateModule

.editIconupdateModule {
	width: 24px;
}

.logoDivdataTable {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #F5F5F5;
	overflow: hidden;
	padding-block: 7px;
}
.logoDivdataTableCategory{
	.logoImagedataTable{
		max-width: 100%;
		max-height: 100%;
		object-fit: fill;
	}
}

.logoImagedataTable {
	max-width: 100%; /* Ensure the image doesn't exceed the container width */
    max-height: 100%; /* Ensure the image doesn't exceed the container height */
	width: auto;
	height: auto;
	// border: 1px solid #E5E5E5;
	// border-radius: 3px;
}

.logoImagedataTable.second {
	position: relative;
}

.imagesCountProductListing {
	position: absolute;
	// right: 18px;
	background: #03030370;
	z-index: 10;
	font-size: 12px;
	width: 40px;
	height: 40px;
	margin: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	text-align: center;
	font-weight: 500;
}

.bannerImagedataTable {
	width: 42px;
	height: auto;
	display: flex !important;
	justify-content: center !important;
	justify-self: center !important;
}

// dataTable  --Category

.logoDivdataTableCategory {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #F5F5F5;
	overflow: hidden;
	// padding-block: 7px;
}

.viewEditDivFlexdataTableCategory {
	display: flex;
	align-items: center;
	gap: 24px;
	box-sizing: border-box;
	justify-content: center;
	text-align: center;
}

.viewEditBtndataTableCategory {
	color: #D71513;
	border: 1px solid #D71513;
	padding: 6px 10px;
	background: #FFEFEF;
	border-radius: 5px;
	box-sizing: border-box;
	font-size: 12px;
}


.subCategoryCountdataTableCategory {
	position: relative;
	top: 5px;
}

// .toggleHomePagedataTableCategory {
// 	color: #D71513;
// }


// subDataTable 

.catNameImgDivFlexsubDataTable {
	display: flex;
	align-items: center;
	gap: 18px;
	padding-block: 32px;
}

.catImgDivsubDataTable {
	border: 1px solid #e5e5e5c1;
	border-radius: 3px;
	width: 90px;
	height: 88px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
}

.catImgsubDataTable {
	width: 100%;
	height: auto;
}

.catNamesubDataTable {
	font-size: 20px;
	font-weight: 500;
}


// addSubModule

.dropdownaddSubModule {
	width: 100%;
	background: transparent;
	color: rgb(153, 146, 146);
}


.mainCategorySelectionaddSubModule {
	padding-top: 32px;
	padding-bottom: 24px;
}

.subCategorySelectionaddSubModule {
	padding-top: 0px;
	padding-bottom: 24px;
}

.labelmainCategorySelectionaddSubModule {
	display: block;
	padding-bottom: 10px;
	color: black;
}


.selectmainCategoryaddSubModule {
	// width: 100%;
	// padding-inline: 14px;
	// padding-block: 8px;
	// border-radius: 8px;
	// color: #9c9ea2;
	// border: 1px solid #D0D5DD;
	// outline: none;
	// list-style: none;
	border: none !important;
	width: 100%;

	outline: none !important;

	border-right: 1px solid #00000044 !important;
	margin-block: 9px !important;
	padding-block: 0px !important;
	border-radius: 0px !important;
}
.cardComponentProductPage{
	.selectmainCategoryaddSubModule{
		border: none !important;
	}
}
.selecthereaddSubModule {
	color: #D0D5DD;
	border: 1px solid #D0D5DD;

}


.selectBrandsDisplayListaddSubModule  {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	color: black;
	gap: 6px;
	padding-left: 0px; 
	align-items: center;
	margin: 0px;
	padding-block: 10px;
}

.selectedBrandLiaddSubModule {
	border: 1px solid #D0D5DD;
	padding-inline: 4px;
	padding-block: 3px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	align-content: center;
	text-align: center;
	width: 100%;
}

.selectedBrandRemoveIconaddSubModule {
	font-size: 5px;
}	

.password-block{
	position: relative;
	&-icon-group{
		position: absolute;
		top: 50%;
		color: #cccccc;
		cursor: pointer;
		right: 10px;
		transform: translateY(-12px);
	}
}
.all-category-block{
	display: flex;
	gap: 15px;
    width: 100%;
    flex: 0 0 100% !important;
}
.all-category-add-more-btn{
	color: #D71513;
    outline: none;
    font-weight: 500;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 16px;
	background: transparent;
    border: none;
	line-height: 100%;
	padding-top: 23px;
}
.cancelledOrderTableContent{
	height: auto;
	width: auto;
	display: flex;
	gap: 15px;
	align-items: center;
	img{
		height: 40px;
		width: 40px;
		border-radius: 3px;
	}
	p{
		margin-bottom: 0;
		color: gray;
		font-weight: 500;
	}
}
.cancelled-order-table{
	.rdt_TableCol,
	.rdt_TableCell{ 
		justify-content: start;
	}
}
.cancelled-ordered-product-count{
	font-size: 14px;
	color: #262626;
	font-weight: 600;
}
.divFlexOrderedProduct.cancelled-ordered-divFlexOrderedProduct{
	.imageOrderedProduct{
		height: 80px;
		width: 80px;
	}
	.orderedProductImageDiv{
		flex: 1 1 0;
	}
	.divColOrderedProduct{
		flex: 7 2 0;
		flex-direction: row;
	}
	.cancelled-ordered-product-price{
		font-size: 14px;
		font-weight: 600;
		color: #D71513;
	}
}
.cancelled-order-merchant-details-block-wrap{
	margin-top: 20px;
	h3{
		margin-bottom: 20px;
		padding-bottom: 0;
	}
}
.cancelled-ordered-product-item{
	border-bottom: 1px solid #E5E5E5;
	margin-bottom: 15px;
	.divFlexOrderedProduct{
		border-bottom: none;
	}
	.cancelled-ordered-addons-item{
		font-size: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		p{
			color: #737373;
			margin-bottom: 0;
		}
		span{
			color: #262626;
		}
	}
	.cancelled-ordered-adons-title{
		font-size: 12px;
		font-weight: 600;
		color: #262626;
		margin-bottom: 10px;
	}
	.cancelled-ordered-product-type{
		margin-bottom: 0;
	}
	.orderedProductVariationQuantityBox{
		width: auto;
		padding: 2px 0px;
	}
}
.cancelled-order-merchant-details-block,
.cancelled-order-customer-details-block{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.cancelled-order-merchant-details-block.in-offer-details{
	margin-bottom: 0;
	padding-bottom: 20px;
	border-bottom: 1px solid #EAECF0;
}

.sell-p-coin-button{
	&-group{
		display: flex;
		align-items: center;
		gap: 15px;
	}
	&-approve{
		display: flex;
		justify-content: center;
		align-items: center;
		background: #16B84D;
		height: 31px;
		width: 90px;
		border-radius: 5px;
		color: #FFFFFF;
		border: none;
		box-shadow: none;
		font-size: 15px;
		&.v-otlined{
			background: #FFFFFF;
			color: #16B84D;
		}
	}
	&-rejected{
		display: flex;
		justify-content: center;
		align-items: center;
		background: #D71513;
		height: 31px;
		width: 90px;
		border-radius: 5px;
		color: #FFFFFF;
		border: none;
		box-shadow: none;
		font-size: 15px;
		&.v-otlined{
			background: #FFFFFF;
			color: #D71513;
		}
	}
}
.service-profile-image-block{
	p{
		margin-bottom: 0;
	}
	.selected-image-containeraddModuleAddBanner{
		width: 178px;
		height: 178px;
		border-radius: 50%;
		img{
			border-radius: 50%;
		}
		&::before{
			border-radius: 50%;
		}
	}
	.image-remove-btn{
		width: 150px;
	}
	.form-labeladdModuleBanner{
		width: 178px;
		height: 178px;
		border-radius: 50%;
		&.v2{
		  min-width: auto !important;
		  h6{
			text-align: center;
		  }
		}
	}
}
.service-background-image{
	.image-container{
		.image-remove-btn{
			width: 150px;
		}
	}
}
.show-Password-block{
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 5px;
	svg{
		cursor: pointer;
		color: red;
	}
}
.cancelled-orderedAllProductsOrderDelivery{
	padding: 15px;
	background: #f3f3f3;
	border-radius: 5px;
}
.cancelled-order-customer-reward{
	padding: 12px;
	background: #D71513;
	border-radius: 8px;
	color: #FFFFFF;
	display: flex;
    flex-direction: column;
    justify-content: center;
	p{
		font-size: 14px;
		margin-bottom: 5px;
	}
	h6{
		font-size: 16px;
		font-weight: 600;
		color: #FFFFFF;
		margin-bottom: 0;
	}
}
.cancelled-order-merchant-details-block{
	.avatar .avatar-content{
		border-radius: 5px;
	}
	.logoDivdataTableCategoryOrderDelivery,
	.logoImagedataTableOrderDelivery{
		border-radius: 5px;
	}
	.divFlexProfileOrderDeliver{
		align-items: center;
	}
}
.cancelled-order-merchant-credentials-block{
	display: flex;
    flex-wrap: wrap;
    gap: 15px;
	.cancelled-order-merchant-credentials-title,
	.cancelled-order-merchant-credentials-content{
		font-size: 16px;
	}
	.cancelled-order-merchant-credentials-content{
		max-width: 215px;
		word-wrap: break-word;
	}
}
.foodOrderCancelled-details-order-type{
	font-size: 14px;
	color: #D71513;
	font-weight: 600;
}
.foodOrderCancelled-details-order-type-block{
	text-align: right;
}
.group-label{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 10px;
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.1);
	width: 100%;
	margin: 0;
	.group-label-title{
		font-size: 12px;
		font-weight: 500;
		color: #111111;
	}
	.group-label-badge{
		height: 20px;
		width: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		border-radius: 50%;
		background: #D71513;
		color: #FFFFFF;
	}
}
.imageIconDivFlexaddSubModule {
	display: flex;
	gap: 32px;
}
.editor-block{
	.ql-toolbar{
		.ql-custom-button{
			height: 20px;
			width: 100px;
		}
	}
	.raw-html-editor{
		display: none;
	}
	&.raw-html{
		.ql-container{
			display: none;
		}
		.raw-html-editor{
			display: block;
		}
	}
	.raw-editor{
		width: 100%;
		height: 200px;
		border: 1px solid #ccc;
		box-shadow: none;
		&:focus{
			border: 1px solid #ccc;
		}
		&:focus-visible{
			border: 1px solid #ccc;
		}
	}
}
.brandsSelectionListOptionsFlexDivaddSubModule {
	display: flex;
	align-items: center;
	justify-items: space-between;
	justify-content: space-between;
	width: 100%;
	padding-block: 5px;
}

.checkMarkIconBrandSelectionaddSubModule {
	color: #D71513;
}

.imageIconBrandSelectionaddSubModule {
	display: flex;
	align-items: center;
	gap: 4px;
}
.order-fliter-block{
	display: flex;
	gap: 15px;
}
.imageIconBrandSelectionaddFlashSale {
	display: flex;
	align-items: center;
	// gap: 4px;
	justify-content: space-between;
	width: 100%;
	padding-inline: 5px;
}

.flexDivAddFlashSale {
	display: flex;
	align-items: center;
	gap: 10px;
}

.brandLogoaddSubModule {
	width: 20px;
	height: 20px;

}

.brandLogoImgaddSubModule {
	width: 100%;
	height: auto;
	border-radius: 50%;
}

.selectedBrandLogoNameDivFlexaddSubModule {
	display: flex;
	align-items: center;
	gap: 12px;
}

.selectedBrandLogoDivaddSubModule {
	width: 100px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.selectedBrandLogoImageaddSubModule {
	width: auto;
	height: auto;
	max-height: 100%;
	max-width: 100%;
	justify-self: flex-start;
}

.inputFieldTitleaddSubModule {
	font-size: 14px;
	color: black;
	font-weight: 450;
	padding-bottom: 6px;
	margin: 0px;
}

.inputFieldTitleaddSubModule.digitalCodeListing {
	padding: 0px !important;
}

.brandsSelectionContaineraddSubModule {
	padding-block: 32px;

}

.brandTitleaddSubModule {
	font-weight: 600;
}

.CreateBtnDivaddSubModule {
	float: right;
	
}

.redeem-p-coin-limit-block{
	max-width: 380px;
}

// Add Product

.modal-containeraddModule {
	min-width: 1400px;
	overflow: hidden;
}

.divFlexRedemptionaddProduct {
	display: flex;
	align-items: center;
	gap: 8px;
	// padding-block: 24px;
	// padding-inline: 64px;
	// margin-bottom: 18px;
}

.redemptionCheckboxTitleaddProduct {
	font-size: 20px;
	font-weight: 600;
	color: black;
}

.redemptionInputaddProduct {
	width: 24px;
	height: 24px;
	border-radius: 8px;
	border: 1px solid #A3A3A3;
}

.divFlexThumbnailaddProduct {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
}

.form-labeladdModuleaddProduct {
	width: 145px;
	height: 145px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px dashed #d4d4d4a0;
	cursor: pointer;
	border-radius: 5px;
	.logoIconaddModule {
		width: 61px;
		height: auto;
	}
	.image-ratio-message{
		font-size: 11px;
		margin: 0 auto;
		text-align: center;
	}
}
.image-ratio-message{
	font-size: 11px;
	margin: 0 auto;
	text-align: center;
	margin-top: 10px;
    font-size: 12px;
}
.form-labelProductVariantEditProduct{
	.logoIconaddModule{
		width: 55px;
	}
	.image-ratio-message{
		margin-top: 0;
	}
}
.thumbnailTitleaddProduct {
	font-size: 18px;
	font-weight: 600;
	color: black;
}

.DivFlexaddProductFormInputsContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 20px;
	padding-block: 32px;
}

.DivFlexaddProductFormInputsContainer > div {
	flex: 0 0 calc(45%)
}


.mainCategorySelectionaddSubModule.addProduct {
	padding-block: 0px;
}

.subCategorySelectionaddSubModule.addProduct {
	padding-block: 0px;
}

.brandsSelectionContaineraddSubModule.addProduct {
	padding-block: 0px;
	
}


.pcoinsCountInputFieldaddProduct {
	background-image: url('../images/logo/pcoins-logo.png');
  	background-repeat: no-repeat; /* Prevent the image from repeating */
  	background-position: 16px center; /* Adjust the image position (e.g., right and centered) */
  	padding-left: 45px;
  	width: 100%;
	padding-right: 20px;
	padding-block: 8px;
	border-radius: 8px;
	color: black;
	border: 1px solid #D0D5DD;
	outline: none;
}

.DescriptionContaineraddProduct {
	padding-block: 32px;
}

// product listing

.variantContentProductListing {
	white-space: nowrap;
}

.productFlexImagesDivProductListing {
	display: flex;
	align-items: center;
	gap: 8px;
}

.AddNewProductBtnProductListing {
	font-size: 15px;
	font-weight: 500;
	background: #D71513;
	border: none;
	outline: none;
	border-radius: 5px;
	padding-inline: 20px;
	padding-block: 9px;
	color: white;
}

.AddNewProductBtnProductListing.outletListing {
	padding-inline: 10px;
	padding-block: 8px;
	font-size: 14px;
	// margin-inline: 5px !important;
	// min-width: 100% !important;
}

// brandDropDown

.dropdown-dropdownToggle {
	min-width: 100%;
	background-color: white;
	.btn-secondary {
		background-color: white !important;
	}
}

.dropdown-menu {
	min-width: 100%;
}


// payment dropdown

.paymentDropdownTitle {
	font-size: 20px;
	font-weight: 600;
	color: black;
}

// card component

.cardComponentProductPage {
	padding-inline: 32px;
	padding-block: 32px;
	margin-bottom: 18px !important;
}

// variation table

.trashBtnIconVariationTable {
	background: transparent;
	border: none;
	outline: none;
	color: #737373;
}

.editBtnUpdateVariant {
	color: #737373;
}
.ql-container.ql-snow {
	border: none;
	height: 450px;
  }
.noVarsVariationTable {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}


// update Variant for product details page

.variantImagesDivUpdateVariant {
	position: relative;
	width: 130px;
	height: 130px;
}
.selected-image-containeraddModule{
	.selected-imageaddModule{
		object-fit: scale-down;
	}
}


.variantSingleImageUpdateVariant {
	width: 100%;
	height: auto;
}


// category dropdown2

.react-selectCategoryDropDown2  {
	width: 100%;
	color: black;
}

// addSubModule

.addSubModuleFormDivGap {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 14px;
}

.addSubModuleCreateTitleName {
	font-size: 24px;
	font-weight: 800;
}

.catSubCatDivFlexaddSubModule {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

// editproduct variant

.productVariantImagesMainDiv {
	height: 170px;
}

.productVariantVideoMainDiv {
	height: 109px;
}

.editProductSingleVariantFormMainContainer {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.productVariantImagesInnerDiv {
	display: flex;
	align-items: center;
	gap: 17px;
}

.form-labelProductVariantEditProduct {
	width: 130px;
	height: 130px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px dashed #d4d4d4a0;
	cursor: pointer;
	border-radius: 5px;
}

.productVariantImagesTitleName {
	font-size: 18px;
	font-weight: 600;
}


.remove-iconProductVariantEditProduct {
	position: absolute;
	top: 0px;
	right: 0px;
	color: white;
	background:rgb(0, 0, 0);
	border-radius: 50%;

}


.brandDropdownProductListing .dropdown-toggle.custom-btnProductListing {
	// min-width: 100%;
	background-color: white !important;
    color:  #9c9ea2 !important;
    // border: none !important;
    text-align: left;  

    // width: 100%;
	padding-inline: 14px !important;
	padding-block: 8px !important;
	height: 38px;
	min-width: 230px;
	border-radius: 5px;
	// color: #9c9ea2;
	border: 1px solid #D0D5DD !important;
	outline: none;
	list-style: none;
}

.brandDropdownProductListing .dropdown-toggle.custom-btnProductListing::after {
	float: right !important;
}


// category type

.categoryTypeModalMainDiv {
	display: flex;
	flex-direction: column;
	gap: 15px;
}


/* Custom CSS */
.custom-select::after {
    color: black; /* Adjust the size as needed */
}

.select-number-dropdown {
	padding-right: 30px;
}

.errorsmalltext {
	color: #D71513;
	font-size: 14px;
	font-weight: 500;
}
 

.CreateModalShowTitle {
	font-size: 24px;
	color: black;
	font-weight: 900;
}
.offer-status-change-modal{
	.dropdown{
		display: flex;
		justify-content: center;
	}
	.dropdown-menu{
		display: flex;
		flex-direction: column;
		left: 15px !important;
	}
}
.addBrandGapMainDiv {
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding-bottom: 30px;
}
.sell-p-coin-data-table{
	.rdt_TableCol,
	.rdt_TableCell{
		justify-content: start;
	}
}
.edit-offer-modal{
	.addBrandGapMainDiv{
		gap: 8px;
	}
}

.addFoodBrandDiv {
	display: flex;
	flex-direction: column;
	gap: 0px;
	padding-bottom: 30px;
}

.UpdateModuleBrandMainWidth {
	width: 800px !important;
}

.editModalMainWidth {
	min-width: 800px !important;
}

.searchFilterMainFlexDiv {
	display: flex;
	align-items: center;
	gap: 12px;
}


// backdrop background of modal

.modal-backdrop {
	background-color: black !important;
}

.flexDivSelectionReviewsProduct {
	display: flex;
	align-items: center;
	gap: 12px;
}


// react date picker

.react-datepicker {
	font-family: Poppins, sans-serif !important;
	font-size: 16px !important;
	padding-block: 30px !important;
	padding-inline: 40px !important;
	border-radius: 10px !important;
	border: 1px solid #aeaeae6e !important;
}

.react-datepicker__navigation {
	top: 55px !important;
} 

.react-datepicker__header {
	background: transparent !important;
	padding: 0px 8px 12px 0px !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
	font-size: 1.2rem !important;
	padding-bottom: 5px !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
	margin: 0.5rem !important;
}


.flatpickr-months .flatpickr-month {
	background-color: #D71513 !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
	background-color: #D71513 !important;
}
.flatpickr-weekdays {
	background-color: #D71513 !important;
}

span.flatpickr-weekday {
	background-color: #D71513 !important;
	color: white !important;
}

.form-control[readonly] {
	opacity: unset !important;
}

.paddingVariationsAlreadyContainer {
	padding-top: 16px;
	padding-bottom: 30px !important;
}

// .page-item.next-item .page-link:after {
// 	background-image: url('../../assets/images/logo/rightArrowIcon.png') !important;
// }

// .page-item.next-item .page-link {
// 	border-radius: 0px !important;
// }

.page-link {
	padding: .4rem 15px !important;
}

.textLabelBoldLoginPage {
	font-weight: 700;
	font-size: 13px;
}

.BrandBannerImageJustifyCenter {
	display: flex !important;
	justify-content: center !important;
}
 
.dropdown-toggle::after {
	background-image: url('../images/logo/downArrow.png') !important;
	// float: right !important;
	padding-right: 30px !important;
	left: 0px !important;
	background-size: 13px !important;
	
}

.classicShowButtonProductDetailsDescription {
	background: transparent;
	border: none;
	color: #D71513;
	font-weight: 500;
}

.LogoutIconUserDropdown {
	padding-inline: 10px;
	display: flex;
	gap: 10px;
	align-items: center;
}

.LogoutIconUserDropdown:hover {
	color: #D71513;
	cursor: pointer;
}


.divFlexOptionValueFieldXCircle {
	display: flex;
	align-items: center;
	gap: 5px;
	color:#737373;
}

.addMoreValueBtnVariationForm {
	color:#D71513;
	background: transparent;
	border: none;
	outline: none;
	font-weight: 500;
	float: left !important;
	width: fit-content;
    margin-bottom: 20px;
	padding-top: 10px;
	font-size: 16px;
}
.invoice-btn{
    padding: 0px 14px;
    border-radius: 8px;
    background: var(--Primary-500, #D71513);
    color: white;
    font-size: 18px;
    font-weight: 500;
	display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
	cursor: pointer;
}
.VariationFormsTitleEditVariant {
	color: black;
	font-size: 22px;
	font-weight: 600;
}

.VariationSingleFormMainContainerEditVariant {
	border-bottom: 1px solid var(--Border---EDEDED, #EDEDED);
}
.addressOrderManagementDetailMainContainer-address{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	flex: 1 0 0;
}
.addressOrderManagementDetailMainContainer-address.border-right{
	border-right: 1px solid var(--Gray-200, #E5E5E5);
}
.variationFormLabelTextEditVariant {
	color: black;
	font-weight: 500;
}

// SEARCHINPUTFIELD

.searchInputField {
	color: #9c9ea2 !important;
}

.searchbarIcon {
	color: #9c9ea2 !important;
}

.main-menu .main-menu-content {
	margin-top: 20px;
}
.flexRowDivPriceField.pt-1{
	padding-bottom: 10px;
	border-bottom: 1px solid var(--Border---EDEDED, #EDEDED);
}
.flexRowDivPriceField.pt-1.paymentMethodLogo-block{
	border-bottom: none;
}
.paymentMethodLogo{
	display: flex;
	gap: 15px;
	align-items: center;
	span{
		color: var(--Gray-700, #404040);

		/* 16px/Regular */
		font-family: "Public Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}
.react-dataTable.react-dataTable-selectable-rows .react-dataTable {
	overflow-x: auto !important;
	scrollbar-width: thin; /* For Firefox */
	scrollbar-color: #aaaaaa75 #eee; /* For Firefox */
  
	/* For Webkit browsers (Chrome, Safari) */
	&::-webkit-scrollbar {
	  width: 5px; /* Width of the scrollbar */
	}
  
	&::-webkit-scrollbar-thumb {
	  background-color: #aaa; /* Color of the thumb (the draggable part) */
	}
  
	&::-webkit-scrollbar-thumb:hover {
	  background-color: #666; /* Color of the thumb on hover */
	}
  
	&::-webkit-scrollbar-track {
	  background-color: #eee; /* Color of the track (the background of the scrollbar) */
	}
}

.cancelled-order-table{
	.tracking.dropdown.status.toggle{
		margin-bottom: 0 !important;
	}
}

// .page-item.next-item .page-link:after {
// 	background-image: none !important;
// 	border-radius: 0px !important;
// }

.selectCategoryDropdownReactSelect {
	min-width: 226px !important;
	// z-index: 1000 !important;

	// overflow-y: auto !important;
	scrollbar-width: thin !important; /* For Firefox */
	scrollbar-color: #aaaaaa75 #eee; /* For Firefox */
  
	/* For Webkit browsers (Chrome, Safari) */
	&::-webkit-scrollbar {
	  width: 5px !important; /* Width of the scrollbar */
	}
  
	&::-webkit-scrollbar-thumb {
	  background-color: #aaa; /* Color of the thumb (the draggable part) */
	}
  
	&::-webkit-scrollbar-thumb:hover {
	  background-color: #666; /* Color of the thumb on hover */
	}
  
	&::-webkit-scrollbar-track {
	  background-color: #eee; /* Color of the track (the background of the scrollbar) */
	}
}

.react-select-container.selectCategoryDropdownReactSelect {
	scrollbar-width: thin !important; /* For Firefox */
	scrollbar-color: #aaaaaa75 #eee; /* For Firefox */
  
	/* For Webkit browsers (Chrome, Safari) */
	&::-webkit-scrollbar {
	  width: 5px !important; /* Width of the scrollbar */
	}
  
	&::-webkit-scrollbar-thumb {
	  background-color: #aaa; /* Color of the thumb (the draggable part) */
	}
  
	&::-webkit-scrollbar-thumb:hover {
	  background-color: #666; /* Color of the thumb on hover */
	}
  
	&::-webkit-scrollbar-track {
	  background-color: #eee; /* Color of the track (the background of the sollbar) */
	}
}

.featuresCellOfVariationTable {
	// display: grid;
	// place-items: center;
	max-height: 60px !important;
	padding-top: 10px !important;
	// margin-bottom: 20px !important;
}

.featuresCellOfVariationTable.outletTable {
	// display: grid;
	// place-items: center;
	height: unset !important;
	padding-top: 0px !important;
	margin-bottom: 0px !important;
	display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* Limit to two lines */
    line-clamp: 2; /* Fallback for future compatibility */
    max-height: 3.2em; /* Adjust based on the text size and line height */

}

.react-quill-textEditor .ql-container {
	min-height: unset !important;
}

.ql-editor.ql-blank,
.ql-editor  {
	min-height: 180px !important;
}

.ql-editor.ql-blank {
	resize: vertical;
	overflow-y: hidden;
}

.reviewsTitleProductDetail {
	font-weight: 600;
	font-size: 20px;
}

.yellowStatusOrderManagement {
	color: #D8AB0D;
	background: #FFFCF3;
	padding-inline: 10px;
	padding-block: 3px;
	border-radius: 24px;
	border: 1px solid #D8AB0D;
}

.greenStatusOrderManagement {
	color: #07AD36;
	background: #EBFFF0;
	padding-inline: 10px;
	padding-block: 3px;
	border-radius: 24px;
	border: 1px solid #07AD36;
}

.orangeStatusOrderManagement {
	color: #EE8204;
	background: #FFFCF9;
	padding-inline: 10px;
	padding-block: 3px;
	border-radius: 24px;
	border: 1px solid #EE8204;
}

.blueStatusOrderManagement {
	color: #1A56AE !important;
	background: #ECF6FF;
	padding-inline: 10px;
	padding-block: 3px;
	border-radius: 24px;
	border: 1px solid #1A56AE;
}

.purpleStatusOrderManagement {
	color: #0B07AD !important;
	background: #F1F1FF;
	padding-inline: 10px;
	padding-block: 3px;
	border-radius: 24px;
	border: 1px solid #0B07AD;
}

.redStatusOrderManagement {
	color: #D71513 !important;
	background: #FFF0F0;
	padding-inline: 10px;
	padding-block: 3px;
	border-radius: 24px;
	border: 1px solid #D71513;
}

.statusOptionsDropdown {
	max-width: 138px;
	border-radius: 20px !important;
	margin-bottom: 16px !important;
	border: none !important;
}

.editVariantCountedForms {
	display: flex;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	
}

.editVariantCountedForms > div {
	flex: 0 0 calc(48%) !important;
}

.VariationSingleFormMainContainerEditVariantHalfNow {
	background: #FAFAFA;
	padding: 24px;
	border-radius: 5px;
}

.css-14el2xx-placeholder {
	padding-left: 10px !important;
}

.wordsCounterforTextEditor {
	width: 100%;
	float: right;
	display: flex;
	justify-content: flex-end;
}

.strongWordLimit {
	padding-right: 10px;
}

.redTrashBannerList {
	color: #D71513 !important;
	margin-right: 10px;
}

.redTrashBannerList.viewOutlet {
	color: #525252 !important;
	margin-right: 10px;
	min-width: 24px;
}

.form-labeladdModuleBanner {
	min-width: 100% !important;
	background: #F5F5F5;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px dashed #d4d4d4a0;
	height: 220px;
	cursor: pointer;
	border-radius: 5px;
}

.form-labeladdModuleBanner.addOutlet {
	
	min-width: unset!important;
	width: 228px;
	background: transparent;
}

.form-labeladdModuleBanner.menuPage {
	min-height: 360px;
}

.divFlexAddBanner {
	display: flex;
	gap: 10px;
	align-items: center;
	color: black;
}
.divFlexAddBanner > div {
	flex: 0 0 calc(49%);
	display: flex;
	flex-direction: column;
	gap: 6px;
	position: relative;
}

.divChildTitleTypeBannerAdd {
	font-weight: 500;
	color: black;
}

.descriptionContainerAddBanner {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 10px;
	position: relative;
}

.descriptionContainerAddBanner.addDigitalCode {
	margin: 0px !important;
}

.addBannerDescriptionInputfield {
	// height: 90px;
	border-radius: 8px;
	padding: 10px;
	outline: #D71513;
	border: 1px solid #D0D5DD;
	resize: none;
}


.addBannerDescriptionInputfield.normalOutlet {
	height: unset !important;
}

.divFlexRowMainTabsMenuManagement  {
    display: flex;
    flex-direction: row;
    // padding-top: 24px;
    // margin-top: 20px;
    gap: 44px;
    position: relative;
    margin-bottom: 26px;
}


.divFlexRowMainTabsMenuManagement > button {

    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 500;
    color: #737373;
    padding-bottom: 15px;
    // z-index: 100;
    
}

.divFlexRowMainTabsMenuManagement > button:hover {
    color: #D71513;
    border-bottom: 1px solid #D71513;
}

.menuPageMainTitleAtTop {
	color: black;
	font-size: 24px;
	font-weight: 600;
	padding: 38px 24px 0px 24px;
	// padding-bottom: 0px !important;
}

.searchDivFlexdataTable.MenuPage {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.searchMenuAddPage {
	min-width: 480px !important;
}

// F&B Brands Main Page

.divFlexFBBrandsMainContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	align-items: center;
	padding: 24px;
}

.divFlexBrandItemFBBrands {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 0px;
	justify-content: center;
	border: 1px solid #D4D4D4;
	border-radius: 16px;
	width: 429px !important;
	height: 369px;
}

@media screen and (max-width: 1300px) {
	.divFlexBrandItemFBBrands {
		width: 320px !important;
	}
}

.divFlexBrandItemFBBrands.AddBrandFbbrandsBox {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	cursor: pointer;
}

.flexColDescriptionBrandItemFBBrands {
	position: absolute;
	bottom: 0px !important;
	display: flex;
	flex-direction: column;
	padding: 55px 24px 24px 24px;
	gap: 10px;
	justify-content: flex-end;
	overflow: hidden;
	height: 40%;
	width: 100%;

}

.flexRowFirstRowBrandItemFBBBrands {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.brandItemImageFBBrandsDiv {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 100%;
	height: 60%;
	// display: grid;
	// place-items: center;
}

.brandItemImageFBBrands {
	width: 100%;
	height: 100%;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.outletsTitleBrandItem {
	font-size: 18px;
	font-weight: 600;
	color: black;
}

.outletsLogoBrandItemFBBrands {
	color: #D71513;
	width: 19px; 
	height: 17px;
}

.outletsLogoBrandItemFBBrands.bigAddLogo {
	width: 43px;
	height: 37px;
}

.sidebarHomeLogo {
	color:#625f6e !important;
	margin-right: 15px;
	width: 19px; 
	height: 17px;
}


.brandItemAbsoluteDivFbbrands {
	position: absolute;
	top: 43%;
	width: 100%;
	color: white;
	display: flex;
	flex-direction: column;
	gap: 8px;
	justify-content: center;
	justify-items: center;
	align-items: center;
	// display: grid;
	// place-items: center;
}

.brandItemImageFBBrandsContainer {
	background-color: white;
	width: 90%;
	height: 49px;
	border-radius: 24px;
	display: grid;
	place-items: center;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.brandItemFbbrandsAbsoluteTitle {
	font-size: 20px;
	font-weight: 500;
	text-align: left !important;
	width: 100%;
	float: left;
	padding-left: 24px !important;
}

.addBrandFbbBrandBoxTitleBlack {
	color: black;
	font-size: 18px;
	font-weight: 600;
}

.addBrandFbbBrandBoxTitleRed {
	color: #D71513;
	font-size: 20px;
	font-weight: 500;
	padding-top: 7px;
}

.divFlexColViewOutletMainTopBox {
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: flex-start;
	color: black;
	margin-top: 20px;
}

.divFlexColViewOutletMainTopBox.outletMenuItemsListing {
	padding-bottom: 24px;
	border-bottom: 2px solid #d4d4d476;
}

.divFlexColViewOutletTopBoxDetails {
	display: flex;
	flex-direction: column;
	gap: 14px;
	justify-content: flex-start;
}

.flexRowImageDesContianerViewOutlet {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	
}

.flexRowImageDesContianerViewOutletFirst {
	display: flex;
	gap: 14px;
	align-items: center;
}

.flexColIMageDesViewOutletTopBox {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 5px;
	font-size: 18px;
}

.flexColIMageDesViewOutletTopBox.userDetailsPage {
	gap: unset;
}

.ViewOutletMainTopBoxTitle24 {
	font-size: 24px;
	font-weight: 600;
}

.flexColCredViewoutletdetails {
	display: flex;
	flex-direction: column;
	gap: 8px;
	p{
		margin-bottom: 0;
	}
}

.PineflexColCredViewoutletdetails {
	align-self: flex-end;
}

.flexColIMageDesViewOutletTopBoxNameing {
	font-size: 18px;
	font-weight: 500;
}

.flexColIMageDesViewOutletTopBoxMobile {
	font-size: 16px;
}

.flexRowImageDesContianerViewOutletFirstImageItself {
	height: 72px;
	width: 72px;
	display: grid;
	place-items: center;
	background: transparent;
	border-radius: 50%;
	overflow: hidden;
}

.flexRowImageDesContianerViewOutletFirstImageItselfImageImage {
	width: 100%;
	height: auto;
}

.PineflexColCredViewoutletdetailsEditIcon {
	color: #525252;
}

.flexRowMenuListingBtnOutletMenuItems {
	display: flex;
	align-items: center;
	gap: 64px;
}

.brandItemImageFBBrandsContainerImageImage {
	overflow: hidden !important;
	max-height: 100% !important;
	min-width: auto;

}

.brandItemAbsoluteDivFbbrandsEditModule {
	position: absolute;
	top: 20px;
	right: 20px;
	color: black;
	background: white;
	padding: 10px;
	border-radius: 50%;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.flexColMainDinningContainerCreateOutlet {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 18px;
	background-color: #F5F5F5;
	padding: 24px;
}

.addmoreDinningBtnCreateOutlet{
	border: none;
	background: transparent;
	outline: none;
	color: #D71513;
	gap: 12px;
	font-size: 18px;
	font-weight: 600;
	display: flex;
	align-items: center;
}

.PlusIconaddmoreDinningBtnCreateOutlet {
	background-color: #D71513;
	color: white;
	border-radius: 50%;
	// border: 1px solid #D71513;
	padding: 2px;
	height: 20px;
	width: 20px;
}

.flexColTableInputContainerCreateOutlet {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;
	color: black;
	width: 100%;
}

.flexColTableInputContainerCreateOutletInputField {
	padding: 10px 14px;
	border-radius: 8px;
	border: 1px solid #D4D4D4;
	width: 100%;
}

.flexRowTitleRowCreateOutlet {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.viewBtnOutletTable {
	border: none;
	background: transparent;
	outline: none;
	color: #D71513;
	border-bottom: 1px solid #D71513;
}

.downloadQRCodeBtnViewOutletTable {
	border: none;
	outline: none;
	background: #D71513;
	color: white;
	display: flex;
	align-items: center;
	gap: 9px; 
	padding: 7px 16px;
	border-radius: 5px;
}

.flexRowdownloadQRCodeBtnViewOutletTable {
	display: flex;
	align-items: center;
	gap: 24px;
}

.flexRowdownloadQRCodeBtnViewOutletTableDeleteIcon {
	color: #525252;
}

.qrCodeImageOutletViewModal {
	width: 100%;
	height: auto;
}

.cancleBtnOrderManagementUpdate {
	border: none;
	color:#D71513;
	background: transparent;
	border: 2px solid #D71513;
	padding: 5px 13px;
	border-radius: 20px;
	font-weight: 600;
}

.flexRowOrderManagementUpdateTopBox {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	// margin-bottom: 20px;.VariationFormsTitleEditVariant
}

.cancleBtnOrderManagementUpdate:hover {
	box-shadow: inset 0 0 10px rgba(164, 25, 25, 0.3); /* Shadow on hover */
    transform: translateY(2px) translateX(1px); /* Slight upward lift on hover */
    border: 1px solid #d71515;
    color: #d71515;
}

.canceledStatusOrderUpdateModal {
	color:#d71515;
	border: 2px solid #d71515;
	background: #FFF0F0;
	padding: 4px 15px;
	border-radius: 20px;
	font-weight: 600;
	font-size: 14px;
}

.editOutletListingIcon {
	margin-left: 10px;
}

.flexRowAddMenuItemOutletMenuItemslistingImageInputsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.modal-dialog-centeredaddModule.addMenuItemOutletListing {
	min-width: 1000px !important;
}

.flexRowAddMenuItemOutletMenuItemslistingImageInputsContainerFIRSTCHILD {
	flex: .8;
	margin-right: 64px;
	display: flex;
	flex-direction: column;
	gap: 18px;
}

.flexRowAddMenuItemOutletMenuItemslistingImageInputsContainerSECONDCHILD {
	display: grid;
	place-items: center;
	flex: .5;
}

.flexRowAddMenuItemOutletListingCheckboxrRow {
	display: flex;
	align-items: center;
	gap: 8px;
	color: black;
	font-weight: 500;
	padding-top: 22px;
}

.flexRowAddMenuItemOutletListingCheckboxrRowInputItself {
	width: auto;
}

.flexColGrayVariationBoxContainerAddMenuItemOutletListing {
	display: flex;
	flex-direction: column;

}

.flexColMainVariationContainerOutletAddingMenuItem {
	display: flex;
	flex-direction: column;
	
}

.flexRowVariantFormOutletaddmenuitem {
	display: flex;
	align-items: center;
	gap: 12px;
	width: 100%;
}

.titleGrayContainerAddMenuItemOutlet {
	font-size: 20px;
	font-weight: 600;
	color: black;
}

.titleGrayContainerAddMenuItemOutletSmallTitleInputFields {
	font-weight: 500;
}

.flexRowImageDesContianerViewOutletFirstImageItselfImageImageAvatarIcon {
	width: fit-content;
	display: flex;
	justify-content: center;
	margin-inline: auto;
	position: relative;
	top: 10px;
}

.flexRowDivCouponPageheaderSearchFilter {
	display: flex;
	gap: 16px;
}

.nextBtnPaginationTable {
	border: 1px solid #D0D5DD;
	border-radius: 8px;
	padding-block: 8px;
	padding-inline: 14px;
}

.tablePaginationText {
	color: black !important;
	font-weight: 500 !important;
}

.flexRowCatPriceAddDigitalCodeModal {
	display: flex;
	gap: 18px;
	align-items: center;
	width: 100%;
}

.flexRowCatPriceAddDigitalCodeModal > * {
	flex: 0 0 calc(48%);
}

.grayRemoveIcon {
	color:#666;
}

.mainCreateCouponContainer {
	padding: 32px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
}

.mainTitleCreateCouponPage {
	font-size: 24px;
	color: black;
	font-weight: 900;
}

.firstMainChildCouponCreateContainer {
	padding: 32px;
	width: 100%;
	border: 1px solid #E5E5E5;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.firstMainChildCouponCreateContainer.setUsage {
	width: 50%;
}

.flexColInputContainerCreateCoupon {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.flexRowActiveBtnCreatecoupon {
	display: flex;
	align-items: center;
	gap: 24px;
}

.secondChildContainerCreateCoupon {
	display: flex;
	flex-direction: column;
}

.lastChildContainerCreateCoupon {
	display: flex;
	align-items: center;
	gap: 24px;
}

.createCouponNextBtn {
	font-size: 16px;
	font-weight: 500;
	background-color:#D71513;
	color: white;
	outline: none;
	border: none;
	border-radius: 8px;
	width: fit-content;
	padding-inline: 100px;
	padding-block: 12px;

}

.firstMainChildCouponCreateContainer.datetimesCreateCoupon {
	padding-block: 50px;
	gap: 70px;
}

.exportCsvBtn {
	background-color: #FFE3E2;
	border: 1px solid #D71513;
	border-radius: 8px;
}

.csvexportBtnText {
	color: #D71513;
	font-weight: 600;
}

.flexColMainContainerTimingFormAddOutletExplore {
	display: flex;
	flex-direction: column;
	gap: 18px;
	color: black;
	background-color: #F5F5F5;
	padding: 24px;
	border-radius: 8px;
}

.TimingFormTitle {
	font-size: 20px;
	font-weight: 700;
}

.dayFormTimingForm {
	display: flex;
	flex-direction: column;
	gap: 22px;
	padding-block: 18px;
	border-bottom: 1px solid #D4D4D4;
}

.flexRowFirstChildTimingFormDayForm {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.flexRowToggleTimingform {
	display: flex;
	align-items: center;
	gap: 8px;
}

.openclosetimingform {
	font-weight: 500;
}

.dayinWordtimingform {
	font-size: 17px;
	font-weight: 600;
}

.flexRowCloseOpentimingContainer {
	display: flex;
	align-items: center;
	gap: 18px;
}

.flexColInputTimingForm {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.labelSelectDate.addOutletExplore {
	margin-bottom: 5px;
}

.mainContainerUserDetailsPage {
	display: flex;
	flex-direction: column;
	padding: 32px;
}

.flexRowMainUserComponent {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.flexColFirstUserChildComponent {
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: flex-start;
}

.flexRowPicDetailsMainContainer {
	display: flex;
	align-items: center;
	gap: 14px;
}

.flexRowCodeListingPageAddExport {
	display: flex;
	align-items: center;
	gap: 10px;
}

.flexRowRatingReviewsUserPage {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 7px;
}

.ratingNoReviewsUserPage {
	font-size: 14px;
	font-weight: 500;
}

.userDetailsPicDetailText {
	font-size: 16px;
}

.userDetailsPicDetailTextName {
	font-size: 20px;
	font-weight: 500;
}

.userObjectBottomFlexRowcontainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.flexColLeftSideContainerUserObjectDetails {
	display: flex;
	flex-direction: column;
	gap: 16px;
	justify-content: flex-start;
}

.flexColLeftSideContainerUserObjectDetailsLastChild {
	display: flex;
	flex-direction: column;
	gap: 6px;
	justify-content: flex-start;
}

.flexRowPcoinBalanceUserDetails {
	display: flex;
	align-items: center;
	gap: 9px;

}

.flexColRightSideContainerUserObjectDetails {
	display: flex;
	flex-direction: column;
	gap: 46px;
	justify-content: flex-start;
}

.flexColRightSideContainerUserObjectDetailsLastChild {
	display: flex;
	flex-direction: column;
	gap: 8px;

}

.redEyeViewOutlet {
	color: #D71513,
}

.smallGuidanceTextCreateVoucherValue {
	font-size: 12px;
	font-weight: 500;
}

.divFlexRowCalenderCreateCoupon  {
	display: flex;
	align-items: center;
}

.calenderIconCreateCoupon {
	margin-left: -30px;
}

.redOrderManagementDetailCount {
	font-weight: 500;
	color: #168952;
}

.simpleOrderManagementDetailsPrice {
	color: black;
	font-weight: 500;
}

.TotalOrderManagementDetailsPrice  {
	color: black;
	font-weight: 600;
	font-size: 18px;
}

.orderedProductTitleOrderManagement {
	font-weight: 500;
	font-size: 16px;
	color: var(--Gray-700, #404040);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.orderedProductOrderManagementAttributes  {
	font-weight: 450;
}

.avatarOrderManagementProfile {
	padding: 20px;
	font-size: 20px !important;
}

.orderManagementUserNameAddressDetails {
	font-weight: 800;
	color: black;
	margin-bottom: 10px;
}

.mobileNoOrderManagementDetails {
	margin-top: 10px;
}

.addressOrderManagementDetailMainContainer {
	font-weight: 450;
	color: #888686;
	display: flex;
	padding: 24px;
	align-items: flex-start;
	gap: 24px;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid var(--Gray-200, #E5E5E5);
	background: var(--Gray-50, #FAFAFA);
}

.titlePriceOrderManagementDetails {
	font-weight: 500;
	font-size: 16px;
}

.grayEditIconVoucherListing {
	color: #737373;
}

.description-cell {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* Limit to two lines */
    line-clamp: 2; /* Fallback for future compatibility */
    max-height: 3.2em; /* Adjust based on the text size and line height */
	p{
		margin-bottom: 0;
	}
}


.flexRowProductsSelectionCreateCoupon {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.flextoggleproductselectioncreateCoupon {
	display: flex;
	align-items: center;
	gap: 10px;
}

.flextoggleproductselectioncreateCouponText {
	font-weight: 800;
}

.flexRowFBrandItemToggleContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.searchFilterMainFlexDiv{
	div[class^="-control"]{
		&:hover{
			border-color: #D71513;
		}
	}
}

// Location-picker
.location-picker-input-block{
	[class$="-control"]{
		border: 1px solid #D0D5DD;
		border-radius: 8px;
		box-shadow: none;
	}
	[class$="-indicatorSeparator"]{
		display: none;
	}
}
.location-picker-input-label{
    font-weight: 500;
    color: black;
	margin-bottom: 8px;
}

.dynamic-price-title-block{
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	width: 100%;
	h3{
		color: var(--Gray-800, #262626);
		font-size: 22px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin: 0;
	}
	button{
		background: none;
		border: none;
		color: var(--Primary-500, #D71513);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		display: flex;
		align-items: center;
		gap: 5px;
		svg{
			display: flex;
			width: 24px;
			height: 24px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			border-radius: 1000px;
			color: #ffffff;
			background: var(--Primary-500, #D71513);
		}
	}
}

.dynamic-price-weight-item{
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 15px;
	padding-bottom: 18px;
	margin-bottom: 18px;
	border-bottom: 1px solid var(--Gray-300, #D4D4D4);
}
.dynamic-item-remove-btn{
	margin-top: 20px;
}
.dynamic-price-block{
	display: flex;
	padding: 24px;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 8px;
	background: var(--Gray-100, #F5F5F5);
}
.dynamic-price-weight-item-wrap{
	max-height: 350px;
	overflow: hidden;
	overflow-y: scroll;
}

.view-default-postCode-price-block{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	h4{
		color: var(--Gray-800, #262626);
		font-size: 22px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin: 0;
	}
}
.CreateModalShowTitle-font-16{
	font-size: 16px;
	color: #000000;
	font-weight: 600;
}
.view-default-postCode-right-align{
	text-align: right;
}

.view-default-postCode-title-block{
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.view-dynamic-postCode-price-block-wrap{
	display: flex;
	padding: 24px;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 8px;
	background: var(--Gray-100, #F5F5F5);
	margin-bottom: 15px;
	h3{
		color: var(--Gray-800, #262626);
		font-size: 22px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 30px;
	}
}
.view-dynamic-postCode-price-block{
	width: 100%;
}
.view-dynamic-postCode-price-item{
	display: flex;
	justify-content: space-between;
	align-items: center;
	h5{
		color: var(--Gray-600, #525252);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
}

.view-dynamic-postCode-price-item + 
.view-dynamic-postCode-price-item{
	margin-top: 10px;
}
