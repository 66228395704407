.validationMainFlexContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    gap: 22px;
}

.validationMainFlexContainer > div {
    flex: 0 0 calc(48%)
}

.labelSelectDate {
    color: black;
    font-weight: 500;
}

.flexDivSelectAddFlashsale {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

/* Example CSS for adding space between date and time in Flatpickr */
.custom-flatpickr .flatpickr-input {
    /* Adjust the padding or margin to create space */
    padding-right: 20px !important; /* You can adjust this value as needed */
  }
  