

.statusOptionsDropdown.css-b62m3t-container {
    outline: none !important;
    padding-inline: 5px !important;
}

.statusOptionsDropdown {
    border-radius: 20px;
    padding: 0 !important;
  }
  
  .statusOptionsDropdown .react-select__control {
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 0 !important;
  }

  .statusOptionsDropdown .react-select__control:focus {
    border: 0px !important;
  }

 
  .statusOptionsDropdown .react-select__menu {
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 0 !important;
  }
  
  .css-1okebmr-indicatorSeparator {
    display: none !important;
}