.variationFormsMainDiv {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    flex-wrap: wrap;
}

.variationFormsMainDiv > div {
    flex: 0 0 calc(50%);
    // width: auto;
}

.addVariationbtn {
    background: #FAFAFA;
    border: none;
    outline: none;
    width: auto;
    color: #D71513;
    font-size: 20px;
    padding: 24px;
    flex: 1;
    text-align: left;
}

.singleVariationMainDiv {
    background: #FAFAFA;
    padding: 24px;
}

.trashbtnaddVariation {
    background: transparent;
    border: none;
    outline: none;
    color: #737373;
    width: fit-content !important;
}

.inputTrashflexDivaddVariation {
    display: flex;
    align-items: flex-start;
}

// .inputTrashflexDivaddVariation > * {
//     flex: 0 0 calc(50%);

// }

.inputsSingleAddVariation {
    flex: 1;
}

// .variationInputFieldSingleVariationForm {
//     margin-bottom: 10px;
// }

.doneVariationbtn {
    background: transparent;
    padding-inline: 35px;
    padding-block: 10px;
    border-radius: 8px;
    border: 1px solid #404040;
}

.custom-btnVariation {
    min-width: 100%;
	background-color: white !important;
    color:  #9c9ea2 !important;
    // border: none !important;
    text-align: left;  

    // width: 100%;
	// padding-inline: 14px;
	// padding-block: 8px;
	border-radius: 8px;
	color: #9c9ea2;
	border: 1px solid #D0D5DD !important;
	outline: none;
	list-style: none;
    padding: 0px !important;
}


// OptionDropdown

.createOptionInputField {
    border: 1px solid #D9D9D9;
    outline: none;
    width: 90%;
    border-radius: 8px;
    padding-block: 8px;
    padding-inline: 10px;
}

.addCreateOption {
    border: none;
    background: transparent;
}

.createOptionButton {
    border: none;
    background: transparent;
    color:#D71513;
    outline: none;
}

.smallbtnOptionDropdown {
    border: none;
    background: transparent;
    color:#D71513;
    outline: none;
}

// VariationForms

.variationTableInput {
    width: 108px;
    padding-inline: 12px;
    padding-block: 8px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    // margin-inline: 5px;
    margin-top: 8px;
    margin-right: 30px;
    // margin-inline: 10px;
}

.variationTableInputSku {
    width: 130px;
    padding-inline: 12px;
    padding-block: 8px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    margin-top: 8px;
    // margin-inline: 5px;
}
.variationTableSKU{
    margin-bottom: 0;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.variationTableVariantName,
.variationTableCheckbox {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.variationTableVariantName {
    padding-inline: 0px !important;
    font-size: 14px;
    width: 100%;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis;
}