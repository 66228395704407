.modal-dialog-centeredaddModule {
    width: 800px;
  //  color: red;
    
}

.modal-dialog-centeredaddModule.viewOutlet {
    min-width: 850px !important;
}

.modal-dialog-centeredaddModule.menuPage {
    min-width: 1400px !important;
   // color: red;
    
}

.row-to-edit {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
}

.row-to-edit.addBanner {
    flex-direction: column;
}

.row-to-edit.addBanner.menuPage {
    flex-direction: column;
    margin-bottom: 25px;
}

.selected-image-containeraddModule {
	width: 190px;
	height: 190px;
}

.selected-imageaddModule {
	width: 100%;
	height: auto;
}

.errorsmalltext.addBanner {
    position: absolute;
    bottom: -30px;
}


.shimmer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  
    .shimmer {
      width: 100%;
      height: 40px;
      background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
      background-size: 200% 100%;
      animation: shimmer 1.5s infinite linear;
      border-radius: 5px;
  
      &-small {
        width: 60%;
        height: 20px;
      }
  
      &-circle {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
