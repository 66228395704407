.VariationSingleFormMainContainer {
    max-width: 660px;
    background: #FAFAFA;
    padding: 24px;
    border-radius: 5px;
}

.nameInputRemoveBtnDivFlexVariationForms {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.labelOptionNameDivFlex {
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex: 1;
}

.labelOptionNameDivFlexTracking {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    align-items: center;
}

.trashBtnVariationForms {
    background: none;
    border: none;
    padding-block: 10px;
    color: #737373;
}

.nameInputFieldVariationForms {
    flex: 1;
}

.optionValuesContainerVariationForms {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.variationFormLabelText {
    font-weight: 500;
    font-size: 14px;
}

.VariationFormsTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 32px;
}

.doneBtnVariationForm {
    color: black;
    width: 134px;
    height: 43px;
    background: white;
    border-radius: 8px;
    border: 1px solid #404040;
}

.doneBtnVariationForm:hover {
    box-shadow: inset 0 0 10px rgba(164, 25, 25, 0.3); /* Shadow on hover */
    transform: translateY(2px) translateX(1px); /* Slight upward lift on hover */
    border: 1px solid #d71515;
    color: #d71515;
}

.FormsAddBtnFlexDiv {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}

.FormsAddBtnFlexDiv > * {
    flex: 0 0 calc(48%);
} 

.addBtnVariationForm {
    // max-width: 660px;
    height: 72px;
    background: #FAFAFA;
    font-size: 20px;
    font-weight: 500;
    color: #D71513;
    border: none;
    text-align: left;
    padding: 24px;
}

.variationNameDropdownVariationForm {
    border-radius: 8px;
    padding-inline: 14px;
    padding-block: 10px;
    outline: none;
}

.divFlexCreateNewOptionName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;

}

.addCancelCreateDiv {
    display: flex;
    align-items: center;
    // justify-content: space-between;
}

.createDecorativeBtn {
    color: #D71513;
    border: none;
    outline: none;
    background: transparent;
}

.brandDropdown .dropdown-toggle.custom-btnSelectDropdown {
    min-width: 100%;
	background-color: white !important;
    color:  #9c9ea2 !important;
    // border: none !important;
    text-align: left;  

    width: 100%;
	padding-inline: 14px !important;
	padding-block: 10px !important;
	border-radius: 8px;
	color: #9c9ea2;
	border: 1px solid #D0D5DD !important;
	outline: none;
	list-style: none;
    
}

.brandDropdown .dropdown-toggle.custom-btnSelectDropdown::after {
    float: right !important;
}

.brandDropdown .brandDropdownMain .custom-btnSelectDropdown .createMainBtnSelectDropdown {
    color: #D71513 !important;

}

