.divFlexCardsOrderManagement {
    display: flex;
    justify-content: space-between;
}

.divFlexCardsOrderManagement > div {
    flex: 0 0 calc(32%);
    
}

// .divFlexCardsOrderManagement > Card {

// }

.firstTopdivFlexColorderManagement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    flex: 1;
    min-height: 135px !important;
    padding-inline: 28px !important;
    gap: 17px;
    border-radius: 8px !important;
}

.firstTopdivFlexColorderManagement.first {
    background-color: #ECF6FF;
}

.firstTopdivFlexColorderManagement.second {
    background-color: #F6F4FF;
}

.firstTopdivFlexColorderManagement.third {
    background-color: #FFECE6;
}

.divFlexNumberIcon {
    display: flex;
    width: 100% !important;
    align-items: center;
    justify-content: space-between !important;
}

.numberCountOrders {
    font-size: 20px;
    font-weight: 700;
}

.numberBoldOrderManagement {
    font-weight: 600 !important;
}

.numberBoldOrderManagement.first {
    color: #1A56AE;
}

.numberBoldOrderManagement.second {
    color: #5743BE;
}

.numberBoldOrderManagement.third {
    color: #F85F1C;
}

.packageIconOrderManagement {
    background-color: white;
    padding: 12px;
    border-radius: 50%;
}

.packageIconOrderManagement.first {
    color: #1A56AE;
}

.packageIconOrderManagement.second {
    color: #5743BE;
}

.packageIconOrderManagement.third {
    color: #F85F1C;
}

.profileFlexMainDelivery {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    min-height: 100% !important;
}

.divFlexProfileOrderDeliver {
    display: flex;
    gap: 12px;
}

.divFlexColProfileDetailsDeliveryOrder {
    display: flex;
    flex-direction: column;
    // gap: 3px;
}

.mainDeliveryContainerOrderDeliver {
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-size: 16px !important;
}

.mainDeliveryContainerOrderDeliverPriceContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.flexRowDivPriceField {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.usernameDeliverOrder {
    color: black !important;
    font-weight: 600;
    margin-top: 20px;
}

.eOrder {
    color: black !important;
    font-weight: 400;
    margin-top: 2px;
}

.eOrderHeading {
    color: black !important;
    font-weight: 600;
    margin-top: 2px;
}

.eOrderHeading2 {
    color: red !important;
    font-weight: 500;
    margin-top: 2px;
}

.usernameDeliverOrder.username {
    padding-bottom: 10px !important;

}

.mobileOrderDeliver {
    color: black;
    font-weight: 500;
}

.SecTitleDeliverOrder {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 10px;
}

.borderDivOrderedDelivery {
    margin-bottom: 10px;
}

.totalPriceDeliveryOrder {
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.divFlexOrderedProduct {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    border-bottom: 1px solid rgba(0, 0, 0, 0.157);
    padding-bottom: 10px;
}

.divColOrderedProduct {
    display: flex;
    flex-direction: column;
    color: black;
    justify-content: space-between;
    gap: 12px;
}

.orderedProductImageDiv {
    width: 80px;
    height: 80px;
}

.imageOrderedProduct {
    width: 100%;
}

.orderedAllProductsOrderDelivery {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.logoImagedataTableOrderDelivery {
    // min-width: 100%; 
    min-height: 100%; 
    // width: auto;
    // height: auto;
    // border: 1px solid #E5E5E5;
    border-radius: 50%;

}

.logoDivdataTableCategoryOrderDelivery {
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid #F5F5F5;
    border-radius: 50%;
    overflow: hidden;
    // padding-block: 7px;
}

.userOrderedDelivery {
    font-weight: 800;
    color: black;
    font-size: 18px;
}

.userDetailOrderedDelivery {
    font-size: 14px;
    font-weight: 500;
}


// tabs

.divFlexRowMainTabsOrderManagement  {
    display: flex;
    flex-direction: row;
    padding-top: 24px;
    margin-top: 20px;
    gap: 44px;
    position: relative;
    
}


.divFlexRowMainTabsOrderManagement > button {

    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 500;
    color: #737373;
    padding-bottom: 15px;
    // z-index: 100;
    
}

.divFlexRowMainTabsOrderManagement > button:hover {
    color: #D71513;
    border-bottom: 1px solid #D71513;
}

.orderManagementTable {
    border-top: 1px solid rgba(0, 0, 0, 0.091);
    // z-index: 9;
    padding-top: 30px;
    margin-top: -1px;
}

.redTabBtnOrderManagement {
    color: #D71513 !important;
    border-bottom: 1px solid #D71513 !important;
}

// .whiteTabBtnOrderManagement {
//     color: ''
// }


  

